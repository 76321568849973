<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-button
          type="button"
          variant="outline-secondary"
          class="back-to-list"
          @click="toList"
        >
          {{ $t('general.back') }}
        </b-button>
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group :label="$t('form.photo.label')">
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewEl"
                        rounded
                        :src="$options.filters.mediaUrl(item, 'photo', '80x80')"
                        height="80"
                      />
                    </b-link>
                    <!--/ photo -->

                    <!-- reset -->
                    <b-button
                      v-if="item.photo"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="item.photo = null"
                    >
                      {{ $t('general.reset') }}
                    </b-button>
                    <!--/ reset -->
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <InitFileManager
                      field="photo"
                      type="review_photo"
                      :multiple="false"
                      @fm-selected="selectedPhoto"
                    />

                    <b-row
                      v-if="errors && errors.photo"
                    >
                      <b-col cols="12">
                        <b-form-invalid-feedback :state="false">
                          {{ errors.photo[0] }}
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="errors && errors['photo.type']"
                    >
                      <b-col cols="12">
                        <b-form-invalid-feedback :state="false">
                          {{ errors['photo.type'][0] }}
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>

                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="8"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.position.label')"
                    label-for="position"
                    :state="errors && errors.position ? false : null"
                  >
                    <b-form-input
                      :id="'position'"
                      v-model="item.position"
                      :state="errors && errors.position ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.position">
                      {{ errors.position[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.rating.label')"
                    label-for="rating"
                    :state="errors && errors.rating ? false : null"
                  >
                    <v-select
                      id="rating"
                      v-model="item.rating"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="ratingOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="rating"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.rating">
                      {{ errors.rating[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.type.label')"
                    label-for="type"
                    :state="errors && errors.type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="type"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.type">
                      {{ errors.type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="24"
              md="12"
            >
              <b-form-group
                :label="$t('form.name.label')"
                :label-for="'title'"
              >
                <b-form-input
                  :id="'title'"
                  v-model="item.title"
                  :state="errors && errors.title ? false : null"
                />
                <b-form-invalid-feedback v-if="errors && errors.title">
                  {{ errors.title[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="24"
              md="12"
            >
              <b-form-group
                :label="$t('form.review.label')"
                :label-for="'description'"
                :state="errors && errors.description ? false : null"
              >
                <b-form-textarea
                  :id="'description'"
                  v-model="item.description"
                  :state="errors && errors.description ? false : null"
                />
                <b-form-invalid-feedback v-if="errors && errors.description">
                  {{ errors.description[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'reviews-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import EditorMixin from '@/mixins/EditorMixin'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [GlobalMixin, EditorMixin],
  data() {
    return {
      item: null,
      typeOptions: [
        { label: 'Site', value: 'site' },
        { label: 'Google', value: 'google' },
        { label: 'Facebook', value: 'facebook' },
      ],
      ratingOptions: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
      ],
    }
  },
  async beforeCreate() {
    await this.$http.get(`/api/admin/reviews/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'reviews-index' })
        }
      })
  },
  methods: {
    selectedPhoto(data) {
      this.item.photo = data
    },
    async onSubmit() {
      const data = {
        status: this.item.status,
        position: this.item.position,
        type: this.item.type,
        rating: this.item.rating,
        title: this.item.title,
        description: this.item.description,
        photo: this.item.photo,
      }

      this.$http.put(`/api/admin/reviews/${router.currentRoute.params.id}`, data)
        .then(res => {
          router.replace({
            name: 'reviews-update',
            params: { id: res.data.data.id },
            query: { ...this.$route.query, ...{ created: Math.floor(Date.now() / 1000) } },
          })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Saved!',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData(item) {
      return {
        id: item.id,
        type: item.type,
        position: item.position,
        rating: item.rating,
        status: item.status,
        photo: this.$options.filters.singleMedia(item.photo),
        title: item.title,
        description: item.description,
      }
    },
    toList() {
      router.replace({
        name: 'reviews-index',
        query: { ...this.$route.query, ...{ updated: Math.floor(Date.now() / 1000) } },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.back-to-list {
  position: absolute;
  top: -75px;
  right: -20px;
}
</style>
